<template>
  <div class="section section-rooms">
   
    <div class="textblock">
      <h2 class="headline-keyword">Graustein</h2>
      <p class="copytext">
        …ist ein minimalistischer, von klaren Linien inspirierter Raum, welcher sich für klassische Gesprächstherapie, Beratung und psychiatrische Konsultation und Behandlung eignet. Das <span class="footnote-figure">Tatami<sup>2</sup></span> lässt Natur in den Raum und lädt zu Imaginationen und Entspannungsübungen im Sitzen oder Liegen ein.
      </p>
      <p class="footnotes-text footnotes-text-two">
        Aufgrund der empfindlichen Oberfläche wird das Tatami mit Socken oder barfuß betreten.
      </p>
    <img  class="image-textblock_width"
          :src="this.imageGraustein.images[this.imageGraustein.images.length -1].path"
          :srcSet="this.imageGraustein.srcSet"
          sizes="100vw"
          alt="Graustein, einer von zwei mietbaren Räumen in der Praxisgemeinschaft PRAXIS KOLLEKTIV WOLLZEILE. Ein minimalistischer Raum für klassische Beratung und Gesprächstherapie.">  
    </div>

  </div>
</template>

<script>
export default {
  name: 'SectionRooms',
  data() {
    return {
      imageGraustein: require('@/assets/images/amilasoftic_PraxisKollektivWollzeile-6.jpg'),
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

  sup {
    line-height: 0%;
  }

  .footnotes-text {
    display: flex;
    border-top: 1px solid #000;
    padding-top: var(--spacer-pixel-01);

    font-style: italic;
    font-size: calc(var(--font-size-p) * 0.85);
    line-height: 135%;
  }

  .footnotes-text.footnotes-text-one:before {
    content: "1";
    width: var(--spacer-pixel-02);
  }

  .footnotes-text.footnotes-text-two:before {
    content: "2";
    width: var(--spacer-pixel-02);
  }

</style>
