<template>
  <div class="section section-rooms">
    <div class="textblock">
      <h2 class="headline-keyword">Räume</h2>
      <h3 class="headline-ambient">Verweilen</h3>
      <p class="copytext">
        Wir wollen in unserer Ordination einen Praxisraum schaffen in welchem sowohl Therapeuten, Psychiater, Beraterinnen und Psychologen gerne arbeiten, als auch Patienten und Klientinnen ihre Gedanken frei fließen lassen können. Die Therapieräume Graustein & Kronjuwel sind so konzipiert, dass sie eine ungestörte Kontaktaufnahme und Resonanz zwischen Menschen ermöglichen. Die großzügigen Altbauzimmer bieten mit ihrer stilvollen Komposition einen geschützten Ort und ermöglichen die Entstehung des Beziehungsraumes zwischen Psychotherapeutin und Klient, Berater und Klientin.
      </p>
      <br>
      <h2 class="headline-keyword">Kunstraum</h2>
      <p class="copytext">
        Wir glauben an die Wirkung ästhetischer Objekte: Deswegen wurde ein sensibles Augemerk auf die <span class="footnote-figure">Ästhetik<sup>1</sup></span> des Praxisraumes gelegt. Des Weiteren vereint die Praxisgemeinschaft Kunst, Raum und Leben: Der Praxisraum wird auch als Kunstraum gedacht. Er bietet AbsolventInnen von Kunstakademien im Rahmen von kuratierten Ausstellungen eine Wirkungsfläche und gleichzeitig sowohl TherapeutInnen als auch KlientInnen die Möglichkeit den Kunstwerken sinnlich zu begegnen.
      </p>
      <p class="footnotes-text footnotes-text-one">
        von altgriechisch αἴσθησις aísthēsis »Wahrnehmung«, »Empfindung« 
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionRooms',
  data() {
    return {
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  sup {
    line-height: 0%;
  }

  .footnotes-text {
    display: flex;
    border-top: 1px solid #000;
    padding-top: var(--spacer-pixel-01);

    font-style: italic;
    font-size: calc(var(--font-size-p) * 0.85);
    line-height: 135%;
  }

  .footnotes-text.footnotes-text-one:before {
    content: "1";
    width: var(--spacer-pixel-02);
  }

  .footnotes-text.footnotes-text-two:before {
    content: "2";
    width: var(--spacer-pixel-02);
  }

</style>
