<template>
  <div>
    <ImgSlider />
    <div class="holder-comp">
      <SectionRooms id="rooms" class="section-left"/>
      <SectionRoomsGraustein id="rooms" class="section-right"/>
      <SectionRoomsKronjuwel id="rooms" class="section-left"/>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import ImgSlider from '@/components/ImgSlider.vue'
import SectionRooms from '@/components/SectionRooms.vue'
import SectionRoomsGraustein from '@/components/SectionRoomsGraustein.vue'
import SectionRoomsKronjuwel from '@/components/SectionRoomsKronjuwel.vue'

export default {
  components: {
    ImgSlider,
    SectionRooms,
    SectionRoomsGraustein,
    SectionRoomsKronjuwel,
  },
  metaInfo: {
    title: 'Ordinationsräume Graustein & Kronjuwel | PRAXIS KOLLEKTIV WOLLZEILE',
    meta: [
      {name: 'description', content: `Die künstlerisch eingerichtete Gemeinschaftspraxis bietet die Ordinationsräume Graustein & Kronjuwel und eine vertrauensvolle Atmosphäre.`},
      // Schema.org markup for Google+
      {itemprop: 'description', content: `Die künstlerisch eingerichtete Gemeinschaftspraxis bietet die Ordinationsräume Graustein & Kronjuwel und eine vertrauensvolle Atmosphäre.`},
      {name: 'robots', content: `index, follow`}
    ],
  },
}
</script>

<style lang="scss" scoped>


</style>