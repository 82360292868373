<template>
  <div class="comp-slider">
    <div class="slider">

      <div class="pagination">
        <span   class="pagination-item"
                :class="{ 'pagination-item-active' : index == currentNumber % imagesSlider.length }"
                v-for="(image, index) in this.imagesSlider"
                :key="'pagination-'+index"
                @click="goToImage(index)">
                {{ image.name }}
        </span>
      </div>

      <div  class="image-holder"
            v-for="(image, index) in this.imagesSlider"
            :key="'image-'+index">
        
        <transition-group name="fade">
          <img  v-show="index == currentNumber % imagesSlider.length"
                :src="image.img.images[Math.round(image.img.images.length / 2)].path"
                :srcSet="image.img.srcSet"
                sizes="(min-width: 1000px) 100vw, 100vw"
                :alt="image.img.alt"
                key="image.img.alt">
        </transition-group>
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'slider',
    data() {
      return {
        imagesSlider: [
          {
            img: require('@/assets/images/amilasoftic_PraxisKollektivWollzeile-2.jpg'),
            name: 'Praxisraum Kronjuwel',
            alt: 'Praxisraum Kronjuwel'
          },
          {
            img: require('@/assets/images/amilasoftic_PraxisKollektivWollzeile-5.jpg'),
            name: 'Praxisraum Graustein',
            alt: 'Praxisraum Graustein'
          },
          {
            img: require('@/assets/images/amilasoftic_PraxisKollektivWollzeile-1.jpg'),
            name: 'Wartezimmer',
            alt: 'Wartezimmer'
          },
        ],
        currentNumber: 0,
        timer: null
      }
    },
    mounted() {
      this.startRotation(0);
    },
    methods: {
      startRotation(extraTime) {
        this.timer = setInterval(this.nextRotation, 4000 + extraTime);
      },
      nextRotation() {
        this.currentNumber += 1
      },
      goToImage(index) {
        clearInterval(this.timer);
        this.currentNumber = index;
        this.startRotation(4000);
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped lang="scss">

.fade-enter-active, .fade-leave-active {
  transition: opacity 1.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.comp-slider {
  position: relative;
  width: 100vw;
  height: 100vw;

  @media screen and (min-width: 1000px) {
    height: 100vh;
  }

  .slider {
    width: 100%;
    height: 100%;

    position: absolute;
    right: 0;
    left: 0;

    .pagination {
      position: absolute;
      display: flex;
      flex-direction: row;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: var(--spacer-responsive-03);
      text-align: center;
      z-index: 10;
      flex-wrap: wrap;
      justify-content: center;

      .pagination-item {
        background-color: white;
        padding: calc(var(--spacer-pixel-01) / 2) var(--spacer-pixel-01);
        margin-top: calc(var(--spacer-pixel-01) / 1);
        transition: background-color .3s ease;

        &:not(:last-of-type) {
          margin-right: calc(var(--spacer-pixel-01) / 1);
        }

        &.pagination-item-active {
          background-color: var(--color-stone-02);
        }

        &:hover {
          background-color: var(--color-stone-01);
          cursor: pointer;
        }
      }
    }

    .image-holder {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

</style>
