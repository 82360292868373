<template>
  <div class="section section-rooms">

    <div class="textblock">
      <h2 class="headline-keyword">Kronjuwel</h2>
      <p class="copytext">
        …bietet durch die verspielte Farbgestaltung ein erfrischendes, aber auch gediegenes Ambiente und ermöglicht den Fokus auf Einzel-, Paar- und systemische Familientherapie. Den Spuren des Unbewussten lässt sich in freier Assoziation auf der Couch folgen.
      </p>
      <img  class="image-textblock_width"
            :src="this.imageKronjuwel.images[this.imageKronjuwel.images.length -1].path"
            :srcSet="this.imageKronjuwel.srcSet"
            sizes="(min-width: 800px) 630px, 450px"
            alt="Kronjuwel,einer von zwei mietbaren Räumen in der Praxisgemeinschaft PRAXIS KOLLEKTIV WOLLZEILE. Ein Raum mit verspielter Farbgestaltung und gediegenes Ambiente.">
      <p class="copytext copytext-extra">
        Erfahren Sie mehr über <router-link to="das-kollektiv" class="copytext-link">unsere Mitglieder</router-link> und wie Sie unserem <router-link to="/kollektiv-beitreten" class="copytext-link">Kollektiv beitreten</router-link> können. 
      </p> 
    </div>

  </div>
</template>

<script>
export default {
  name: 'SectionRooms',
  data() {
    return {
      imageKronjuwel: require('@/assets/images/amilasoftic_PraxisKollektivWollzeile-3.jpg')
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .copytext-extra {
    margin-top: calc(var(--spacer-pixel-02) * 2);
  }

</style>
